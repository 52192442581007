import Box from "components/Box";
import { FullOmnosLogo } from "components/OmnosLogo";
import Spacer from "tpo/Spacer";

export default function OmnosHeader() {
  return (
    <>
      <Spacer py={[2, 2, 20]} />
      <Box
        display="flex"
        flexDirection={["column", "column", "row"]}
        gap={[20, 20, 60]}
        alignItems="center"
        maxWidth={760}
        mx="auto"
      >
        <FullOmnosLogo size={[130, 130, 150]} />
        <Box display="flex" flexDirection="column" gap={[20, 20, 30]}>
          <Box color="white" fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
            Omnos shop
          </Box>
          <Box color="white" fontFamily="gilroyMedium" fontSize={14}>
            Welcome to the Omnos Shop! Discover our wide range of functional tests designed to guide
            you on your health & wellness journey.
          </Box>
        </Box>
      </Box>
    </>
  );
}
