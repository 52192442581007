import { matchPath, useHistory, useLocation } from "react-router-dom";

import { useAppState } from "components/AppStateProvider";
import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { useHeaderContext } from "contexts/HeaderContext";
import { PRACTITIONER } from "core/constants";
import {
  DASHBOARD_HOME_URL,
  EXPERTS_URL,
  FOODS_URL,
  ORDERS_URL,
  PARTNERS_PRACTITIONER_URL,
  PARTNERS_PROFILE_URL,
  QUESTIONNAIRE_INTRO_PATTERN_URL,
  QUESTIONNAIRE_OUTRO_PATTERN_URL,
  QUESTIONNAIRE_STEP_PATTERN_URL,
  RECOMMENDATIONS_URL,
  REGISTER_KIT_URL,
  SETTINGS_URL,
  TESTS_URL,
  TEST_RESULTS_URL,
  TEST_RESULTS_URL_BASE,
  THEME_URL,
  UPLOAD_TEST,
  getQuestionnaireIntroUrl,
  getThemeUrl
} from "core/urls";
import useLogOut from "hooks/header/useLogOut";
import usePartnerPageCheck from "hooks/partner/usePartnerPageCheck";
import { ReactComponent as UserIcon } from "images/user.svg";
import ChevronComponent from "tpo/Chevron";
import Group from "tpo/Group";
import LinkWrapper from "tpo/LinkWrapper";
import Modal from "tpo/Modal";
import NavLink from "tpo/NavLink";
import Spacer from "tpo/Spacer";
import { useUserQuestionnaireSubmissionSummaries } from "tpo/UserQuestionnaireSubmissionSummaries";
import ButtonV2, { CloseButton } from "v2/Buttons";

export default function UserMenuDrawer({ bg, user, userSubmissions }) {
  const { opened, onClick, close } = useHeaderContext();

  const { statusElement } = useUserQuestionnaireSubmissionSummaries({
    snapshotQueued: user.snapshotQueued,
    snapshotStatus: user.snapshotStatus,
    userSubmissions
  });

  const { userInfo } = useAppState();
  const isPartner = userInfo?.user?.partnerUserProfile;

  const isPartnerPage = usePartnerPageCheck();

  const location = useLocation();

  const history = useHistory();

  const logout = useLogOut();

  return (
    <Modal
      maxWidth={700}
      bg="dark"
      show={opened}
      close={close}
      mode={[
        "fullScreen",
        "fullScreen",
        {
          topRightPosition: {
            x: [20, 20, "max(calc((100% - 1538px) / 2), 20px)"],
            y: 104
          }
        }
      ]}
      maxHeight="calc(100vh - 104px - 20px)"
      borderRadius={[0, 0, 10]}
      data-component-name="drawer"
      withOverlay={false}
    >
      <Box pr={[24, 24, 40]} pl={[40, 40, 60]} pt={[40, 40, 60]} pb={[60, 60, 80]}>
        <Group
          position="sticky"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          justifyContent="flex-end"
          height={60}
        >
          <CloseButton color="white" onClick={close} size={null} />
        </Group>
        <Box mt={[60]}>
          <ButtonV2
            as={LinkWrapper}
            color="green"
            rightIcon={<ChevronComponent />}
            to={REGISTER_KIT_URL}
            style={{
              display: "inline-flex"
            }}
            onClick={onClick}
            size={["sm", "sm", "md"]}
          >
            Register a kit
          </ButtonV2>
          <Spacer py={2} />
          {user?.partnerUserProfile?.tier === PRACTITIONER && (
            <NavLink
              color="white"
              label="Account"
              fontSize={[18, 18, 24]}
              leftIcon={<UserIcon fill="white" width={20} />}
            >
              <NavLink
                color="white"
                fontSize={[14, 14, 16]}
                label="Personal"
                active={!isPartnerPage}
                onClick={() => {
                  onClick();
                  history.push(DASHBOARD_HOME_URL);
                }}
              />
              <NavLink
                color="white"
                fontSize={[14, 14, 16]}
                label="Partner"
                active={location.pathname === PARTNERS_PROFILE_URL}
                onClick={() => {
                  onClick();
                  history.push(PARTNERS_PROFILE_URL);
                }}
              />
            </NavLink>
          )}
          <NavLink
            color="white"
            label="Dashboard"
            fontSize={[18, 18, 24]}
            active={location.pathname === DASHBOARD_HOME_URL}
            onClick={() => {
              onClick();
              history.push(DASHBOARD_HOME_URL);
            }}
          />
          <NavLink
            color="white"
            label="Shop"
            fontSize={[18, 18, 24]}
            active={location.pathname === TESTS_URL}
            onClick={() => {
              onClick();
              history.push(TESTS_URL);
            }}
          />
          {/* <NavLink
            color="white"
            label="Find a practitioner"
            fontSize={24}
            active={location.pathname === EXPERTS_URL}
            onClick={() => {
              onClick();
              history.push(EXPERTS_URL);
            }}
          /> */}
          <NavLink
            fontSize={[18, 18, 24]}
            color="white"
            label="Self Assessment"
            rightIcon={statusElement}
            active={
              matchPath(location.pathname, {
                path: QUESTIONNAIRE_INTRO_PATTERN_URL,
                exact: true
              }) ||
              matchPath(location.pathname, {
                path: QUESTIONNAIRE_STEP_PATTERN_URL
              }) ||
              matchPath(location.pathname, {
                path: QUESTIONNAIRE_OUTRO_PATTERN_URL,
                exact: true
              })
            }
            onClick={() => {
              onClick();
              history.push(getQuestionnaireIntroUrl("symptoms"));
            }}
          />
          <NavLink
            fontSize={[18, 18, 24]}
            color="white"
            label="Results"
            disabled={
              !user.pageAvailability.testResultsAvailable &&
              !user.pageAvailability.wellnessScoresAvailable &&
              !user.pageAvailability.personalisedRecommendationsAvailable &&
              !user.pageAvailability.foodListAvailable
            }
          >
            <NavLink
              fontSize={[14, 14, 16]}
              color="white"
              label="Test Results"
              disabled={!user.pageAvailability.testResultsAvailable}
              active={matchPath(location.pathname, {
                path: TEST_RESULTS_URL,
                exact: true
              })}
              onClick={() => {
                onClick();
                history.push(TEST_RESULTS_URL_BASE);
              }}
            />
            <NavLink
              fontSize={[14, 14, 16]}
              color="white"
              label="Wellness Scores"
              disabled={!user.pageAvailability.wellnessScoresAvailable}
              active={matchPath(location.pathname, {
                path: THEME_URL,
                exact: true
              })}
              onClick={() => {
                onClick();
                history.push(getThemeUrl("health"));
              }}
            />
            <NavLink
              fontSize={[14, 14, 16]}
              color="white"
              label="Personalised suggestions"
              disabled={!user.pageAvailability.personalisedRecommendationsAvailable}
              active={matchPath(location.pathname, {
                path: RECOMMENDATIONS_URL,
                exact: true
              })}
              onClick={() => {
                onClick();
                history.push(RECOMMENDATIONS_URL);
              }}
            />
            <NavLink
              fontSize={[14, 14, 16]}
              color="white"
              label="Food List"
              disabled={!user.pageAvailability.foodListAvailable}
              active={matchPath(location.pathname, {
                path: FOODS_URL,
                exact: true
              })}
              onClick={() => {
                onClick();
                history.push(FOODS_URL);
              }}
            />
          </NavLink>
          <NavLink
            fontSize={[18, 18, 24]}
            color="white"
            label="Orders"
            active={matchPath(location.pathname, {
              path: ORDERS_URL,
              exact: true
            })}
            onClick={() => {
              onClick();
              history.push(ORDERS_URL);
            }}
          />
          <NavLink
            fontSize={[18, 18, 24]}
            color="white"
            label="Profile"
            active={matchPath(location.pathname, {
              path: SETTINGS_URL,
              exact: true
            })}
            onClick={() => {
              onClick();
              history.push(SETTINGS_URL);
            }}
          />
          {!isPartner && (
            <NavLink color="white" label="Partners" fontSize={[18, 18, 24]}>
              <NavLink
                color="white"
                label="Omnos Partner programme"
                as={ExternalTextLink}
                href="https://www.regeneruslabs.me/practitioner-portal"
                underline={false}
                fontSize={[14, 14, 16]}
              />
              <NavLink
                color="white"
                label="Practitioner application"
                onClick={() => {
                  onClick();
                  history.push(PARTNERS_PRACTITIONER_URL);
                }}
                fontSize={[14, 14, 16]}
              />
            </NavLink>
          )}
          <NavLink
            color="white"
            label="Upload a test result"
            active={matchPath(location.pathname, {
              path: UPLOAD_TEST,
              exact: true
            })}
            onClick={() => {
              onClick();
              history.push(UPLOAD_TEST);
            }}
            fontSize={[18, 18, 24]}
          />
          <NavLink color="red" label="Logout" onClick={logout} fontSize={[18, 18, 24]} />
        </Box>
      </Box>
    </Modal>
  );
}
