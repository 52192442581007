import { useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";
import CustomerPage from "components/CustomerPage";
import Image from "components/Image";
import { InternalTextLink } from "components/Links";
import { theme } from "core/theme";
import Icon from "images/tpo/self_assesment.svg";
import Lottie from "lottie-react";
import ButtonV2 from "v2/Buttons";

import { useAppState } from "../components/AppStateProvider";
import Box from "../components/Box";
import Text from "../components/Text";
import { DASHBOARD_HOME_URL } from "../core/urls";
import SpinnerJSON from "../data/spinner";
import Section from "../deprecated/Section";
import Center from "./Center";
import ChevronComponent from "./Chevron";
import { HeadingExtraExtraSmall } from "./Headings";
import { useSubmission } from "./QuestionnaireHooks";
import Page from "./QuestionnairePages";
import Spacer from "./Spacer";
import Stack from "./Stack";

const COMPLETE_USER_SUBMISSION_MUTATION = gql`
  mutation CompleteUserSubmissionMutation($input: CompleteUserSubmissionMutationInput!) {
    completeUserSubmissionMutation(input: $input) {
      userTestRecommendations {
        id
        product {
          id
          paymentIsoCode
          name
          slug
          productFamily {
            id
            slug
          }
          content {
            id
            image1
          }
        }
        symptoms {
          id
          name
          points
          maxPoints
          score
        }
        score
        points
        maxPoints
      }
      errors {
        field
        messages
      }
    }
  }
`;

export function useOutro(product, partial) {
  const submission = useSubmission();
  const history = useHistory();
  const location = useLocation();
  const { setQuestionnaireProcessing } = useAppState();
  const [completeUserSubmissionMutation] = useMutation(COMPLETE_USER_SUBMISSION_MUTATION);
  const [bestMatchedTest, setBestMatchedTest] = useState();
  const redirectToDashboard = useCallback(() => {
    history.push(DASHBOARD_HOME_URL);
  }, [history]);

  const shouldUpdate = useRef(true);
  shouldUpdate.current = true;

  useEffect(() => {
    if (submission) {
      if (location.state?.fromSection && history.action === "PUSH") {
        completeUserSubmissionMutation({
          variables: {
            input: {
              submission: submission.id,
              complete: true,
              products: product,
              partial
            }
          }
        })
          .then(resp => {
            if (shouldUpdate.current) {
              // if user doesn't wait to get to this point but has by now moved to different page
              // when react gets to here it will warn that it tried to update a mounted component
              // so this flag checks to see if we should update
              setQuestionnaireProcessing(true);
              setBestMatchedTest({
                show: true,
                test: resp.data?.completeUserSubmissionMutation?.userTestRecommendations?.[0]
              });
            }
          })
          .catch(error => {
            // TODO - what should we do here?
            console.log("Error marking the user submission as complete", error);
          });
      } else {
        redirectToDashboard();
      }
    }

    return () => {
      shouldUpdate.current = false;
    };
  }, [
    partial,
    product,
    history,
    redirectToDashboard,
    submission,
    location,
    setQuestionnaireProcessing,
    completeUserSubmissionMutation,
    setBestMatchedTest
  ]);

  return {
    bestMatchedTest
  };
}

export function LoadingResults() {
  return (
    <Page
      header="Processing results..."
      Main={
        <Box pb={theme.spacing.section.pb} px={20}>
          <Box
            bg="white"
            pt={[40, 40, 60]}
            pb={[60, 60, 80]}
            maxWidth={1020}
            mx="auto"
            style={{
              borderRadius: 5
            }}
            px={20}
          >
            <Box maxWidth={760} mx="auto">
              <Section.Header>
                <HeadingExtraExtraSmall fontSize={[18, 18, 24]}>
                  This should take a moment
                </HeadingExtraExtraSmall>
              </Section.Header>
              <Section.BodyCopy>
                <Text fontSize={[14, 14, 16]}>
                  Just a moment, we’re analysing your answers and will shortly have your wellness
                  scores, suggestions and matched tests available for you.
                </Text>
              </Section.BodyCopy>
              <Section.Content>
                <Box display="flex" justifyContent="center">
                  <Box height={50} width={50}>
                    <Lottie animationData={SpinnerJSON} loop />
                  </Box>
                </Box>
              </Section.Content>
            </Box>
          </Box>
        </Box>
      }
    />
  );
}

function NoTestRecommendationsAvailable() {
  return (
    <CustomerPage bg="blue">
      <Stack
        mt={94}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
        minHeight="100%"
        gap={[20, 20, 40]}
        alignItems="center"
        px={20}
      >
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]} color="white">
          Questionnaire
        </Box>
        <Stack
          bg="white"
          px={[20, 20, 130]}
          pt={[40, 40, 60]}
          pb={[60, 60, 80]}
          gap={40}
          maxWidth={1020}
          width="100%"
          borderRadius={5}
        >
          <Stack gap={20}>
            <Image
              src={Icon}
              width={115}
              height={115}
              style={{
                alignSelf: "center"
              }}
            />
            <Box
              fontFamily="gilroyBold"
              fontSize={[18, 18, 24]}
              textAlign="center"
              style={{
                textTransform: "uppercase"
              }}
              as="h2"
            >
              self assessment
            </Box>
          </Stack>
          <Box>
            <Box as="h3" fontFamily="gilroyBold" fontSize={[18, 18, 24]}>
              No test suggestions
            </Box>
            <Spacer py={2} />
            <Box fontSize={[14, 14, 16]} lineHeight="150%">
              Based on the answers to your self assessment questionnaire we are unable to provide
              any suitable tests from our range. Please reach out to a member of our team and we
              will seek to advise further.
            </Box>
          </Box>
          <Center stacked>
            <ButtonV2
              color="dark"
              onClick={() => {
                window.location.href =
                  "mailto:support@omnos.me?subject=Consultation&body=I've just completed the symptom questionnaire and have been advised to get in touch for a free 15 min consultation.";
              }}
              mx="auto"
              rightIcon={<ChevronComponent fill="white" />}
              size={["sm", "sm", "md"]}
            >
              book a free consultation
            </ButtonV2>
            <InternalTextLink href={DASHBOARD_HOME_URL} mt={[20, 20, 30]}>
              Continue to dashboard
            </InternalTextLink>
          </Center>
        </Stack>
      </Stack>
    </CustomerPage>
  );
}

export function Results({ Component, userTestRecommendation, threshold = 0.189999 }) {
  if (userTestRecommendation) {
    if (userTestRecommendation.score > threshold) {
      return <Component userTestRecommendation={userTestRecommendation} />;
    }
  }
  return <NoTestRecommendationsAvailable />;
}
