import { forwardRef } from "react";

import Flicking from "@egjs/react-flicking";

import { gql } from "@apollo/client";
import Box from "components/Box";
import Loading from "components/Loading";
import { TESTS_URL, getTestUrl } from "core/urls";
import { useDataLoader } from "hooks/useDataLoader";
import styled from "styled-components";
import Center from "tpo/Center";
import ChevronComponent from "tpo/Chevron";
import Currency from "tpo/Currency";
import LinkWrapper from "tpo/LinkWrapper";
import Spacer from "tpo/Spacer";
import ButtonV2 from "v2/Buttons";

import TestRecommendationBadge from "./TestRecommendationBadge";

const ImageBox = styled(Box)`
  height: 226px;
  width: 310px;

  @media (min-width: 640px) {
    height: 306px;
    width: 420px;
  }
`;

const Card = forwardRef(
  (
    {
      className,
      name,
      shortDescription,
      price,
      slug,
      productFamily,
      dataComponentName,
      image,
      badge,
      mx
    },
    ref
  ) => {
    const url = getTestUrl(slug, productFamily.slug);

    return (
      <Box
        ref={ref}
        className={className}
        display="flex"
        flexDirection="column"
        dataComponentName={dataComponentName}
        mx={mx}
      >
        <Box bg="haze" display="flex" flexDirection="column" flex={1}>
          <ImageBox
            position="relative"
            style={{
              backgroundImage: `url(${image})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain"
            }}
          >
            {!!badge && (
              <Box position="absolute" zIndex={1}>
                {badge}
              </Box>
            )}
          </ImageBox>
          <Box flex={1} display="flex" flexDirection="column" p={20} borderTop="1px solid #fff">
            <Box fontFamily="gilroyBold" fontSize={18}>
              {name}
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={14}>
              {shortDescription}
            </Box>
            <Box mt="auto">
              <Box display="flex" alignItems="center" justifyContent="space-between" mt={20}>
                <Currency value={price} fontSize={28} />
                <ButtonV2 as={LinkWrapper} color="dark" to={url} size={["xs", "xs", "sm"]}>
                  learn more
                </ButtonV2>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

const OMNOS_TEST_PRODUCTS_QUERY = gql`
  query OmnosTestProductsQuery($productAudience: String, $orderBy: String) {
    consumerTestProducts(productAudience: $productAudience, orderBy: $orderBy) {
      id
      paymentIsoCode
      name
      slug
      userTestRecommendationRank
      productFamily {
        id
        slug
      }
      rrpCurrentPrice
      content {
        id
        image1
      }
    }
  }
`;

export default function DiscoverOtherTestsPanel({ title, subtitle }) {
  const { data, loading } = useDataLoader({
    query: OMNOS_TEST_PRODUCTS_QUERY,
    variables: {
      productAudience: "B2C",
      orderBy: "user_test_recommendation_rank"
    }
  });

  const testProducts = data?.consumerTestProducts;
  const orderedTestProducts = testProducts;

  const showLoadingIcon = !data || loading;

  return (
    <>
      <Box maxWidth={760} mx="auto" px={[20, 20, 0]}>
        <Box fontFamily="gilroyBold" fontSize={[28, 28, 36]}>
          {title}
        </Box>
        <Spacer py={[2, 2, 15]} />
        <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]}>
          {subtitle}
        </Box>
      </Box>
      {showLoadingIcon ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        <Flicking
          moveType="freeScroll"
          align="0"
          bound
          style={{
            paddingLeft: 20,
            paddingRight: 20
          }}
        >
          {orderedTestProducts
            .filter(tp => tp.productFamily)
            .map(tp => (
              <Card
                data-component-name="DiscoverTests:TestProductCard"
                key={tp.id}
                className="card"
                name={tp.name}
                shortDescription={tp.content.shortDescription}
                price={tp.rrpCurrentPrice}
                slug={tp.slug}
                productFamily={tp.productFamily}
                image={tp.content.image1}
                mx={2}
                badge={
                  tp.userTestRecommendationRank !== null && tp.userTestRecommendationRank < 6 ? (
                    <TestRecommendationBadge
                      bg="brand"
                      color="white"
                      rank={tp.userTestRecommendationRank}
                    />
                  ) : null
                }
              />
            ))}
        </Flicking>
      )}
      <ButtonV2
        as={LinkWrapper}
        color="dark"
        rightIcon={<ChevronComponent />}
        mx="auto"
        to={TESTS_URL}
        size={["sm", "sm", "md"]}
      >
        see all omnos tests
      </ButtonV2>
    </>
  );
}

DiscoverOtherTestsPanel.defaultProps = {
  title: "Discover our other tests",
  subtitle:
    "Our series of specialised tests are designed to cover the key areas of health and wellbeing."
};
