import Box from "components/Box";
import Group from "tpo/Group";
import Spacer from "tpo/Spacer";

export default function PatientDetails({ patient }) {
  return (
    <Box data-component-name="PatientDetails">
      <Box fontFamily="gilroyBold" fontSize={18}>
        Patient details
      </Box>
      <Spacer py={2.5} />
      <Group gap={5}>
        <span>Name:</span>
        <span data-component-name="PatientDetails:name">
          {patient.platformUserProfile.user.firstName} {patient.platformUserProfile.user.lastName}
        </span>
      </Group>
      <Group gap={5}>
        <span>Date of birth:</span>
        <span data-component-name="PatientDetails:dateOfBirth">
          {patient.platformUserProfile.dateOfBirth}
        </span>
      </Group>
      <Group gap={5}>
        <span>Sex:</span>
        <span data-component-name="PatientDetails:sex">{patient.platformUserProfile.gender}</span>
      </Group>
      <Group>
        <span>Email:</span>
        <span data-component-name="PatientDetails:email">
          {patient.platformUserProfile.user.email}
        </span>
      </Group>
    </Box>
  );
}
