import { theme } from "core/theme";
import Jumbotron from "tpo/Jumbotron";

import Box from "./Box";
import Page from "./Page";

export function CustomerAccountPage({ children, bg, includeFooter, jumbotronProps = {} }) {
  return (
    <CustomerPage bg={bg} includeFooter={includeFooter} jumbotronProps={jumbotronProps}>
      <Box
        maxWidth={800}
        mx="auto"
        px={20}
        pt={theme.spacing.section.pt}
        pb={theme.spacing.section.pb}
      >
        {children}
      </Box>
    </CustomerPage>
  );
}

export default function CustomerPage({
  children,
  bg,
  includeFooter,
  jumbotronProps = {},
  bodyProps
}) {
  return (
    <Page bg={bg} includeFooter={includeFooter} bodyProps={bodyProps}>
      {!!(jumbotronProps?.title || jumbotronProps.children) && (
        <Jumbotron
          title={jumbotronProps?.title}
          minHeight={[279, 279, 294]}
          pt={[79, 79, 94]}
          {...jumbotronProps}
        />
      )}
      {children}
    </Page>
  );
}
