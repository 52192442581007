import PoweredByOmnos from "images/PoweredByOmnos";
import Center from "tpo/Center";

export default function PoweredByOmnosBanner({ color }) {
  return (
    <Center
      py="9px"
      position="absolute"
      top={0}
      left={0}
      right={0}
      className="PoweredByOmnosBanner"
      height={34}
    >
      <PoweredByOmnos fill={color} />
    </Center>
  );
}

PoweredByOmnosBanner.defaultProps = {
  color: "black"
};
