import { useRef, useState, useCallback, forwardRef, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { GENERATE_PRESIGNED_URL_FOR_USER_UPLOAD_MUTATION } from "graphql/accounts";
import styled from "styled-components";
import ChevronComponent from "tpo/Chevron";
import ButtonV2 from "v2/Buttons";

import Box from "./Box";
import Loading from "./Loading";

export const FileInputWrapper = styled(Box)`
  position: relative;

  input[type="file"] {
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }
`;

function uploadFileToS3(url, fields, file) {
  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) => formData.set(key, value));
  formData.set("file", file, fields.key);
  const request = new Request(url, {
    method: "POST",
    body: formData
  });
  return fetch(request);
}

export const INITIAL_STATUS = "initial";
export const UPLOADING_STATUS = "uploading";
export const SUCCESS_STATUS = "success";
export const ERROR_STATUS = "error";

// TODO - the UploadTest page should make use of this module
// but this PR will merged after the changes that have already been made to the UploadTest page
// on another PR

export function useUploadFileToS3({ publicBucket = false, onChange }) {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const [generatePresignedUrlMutation] = useMutation(
    GENERATE_PRESIGNED_URL_FOR_USER_UPLOAD_MUTATION
  );

  const [status, setStatus] = useState(INITIAL_STATUS);

  const upload = useCallback(
    async event => {
      if (status === UPLOADING_STATUS) return;
      setStatus(UPLOADING_STATUS);
      try {
        const resp = await generatePresignedUrlMutation({
          variables: { fileName: event.target.files[0].name, public: publicBucket }
        });
        const data = resp.data;
        if (
          data.generatePresignedUrlForUserUploadMutation.url &&
          data.generatePresignedUrlForUserUploadMutation.fields
        ) {
          const fields = data.generatePresignedUrlForUserUploadMutation.fields;
          const url = data.generatePresignedUrlForUserUploadMutation.url;
          const fileUrl = data.generatePresignedUrlForUserUploadMutation.fileUrl;
          // fileUrl will only exist if the bucket is public
          try {
            const s3UploadResp = await uploadFileToS3(url, fields, event.target.files[0]);
            if (s3UploadResp.status === 204) {
              onChangeRef.current({
                key: fields.key,
                fileUrl: fileUrl,
                file: event.target.files[0]
              });
              setStatus(SUCCESS_STATUS);
            } else {
              setStatus(ERROR_STATUS);
            }
          } catch (e) {
            setStatus(ERROR_STATUS);
            console.log("Error encountered uploading file to s3", e);
          }
        } else {
          // no presigned url so can't upload
          // not sure this can happen
          setStatus(ERROR_STATUS);
        }
      } catch (e) {
        setStatus(ERROR_STATUS);
        console.log(`Error encountered generating a presigned url for user test upload, ${e}`);
      }
    },
    [generatePresignedUrlMutation, status, publicBucket]
  );

  return {
    status,
    upload
  };
}

export const UploadFileToS3 = forwardRef(
  ({ publicBucket = false, label, onChange, name, value, ...rest }, ref) => {
    const { status, upload } = useUploadFileToS3({ publicBucket, onChange });

    let rightIcon = <ChevronComponent />;
    if (status === UPLOADING_STATUS) {
      rightIcon = <Loading color="white" size={20} minHeight={0} minWidth={20} />;
    }

    return (
      <FileInputWrapper data-component-name="UploadFileToS3" {...rest}>
        <input
          ref={ref}
          type="file"
          onChange={upload}
          disabled={status === UPLOADING_STATUS}
          data-testid="hiddenFileInput"
        />
        <ButtonV2
          color="dark"
          rightIcon={rightIcon}
          type="button"
          width="100%"
          style={{
            pointerEvents: "none"
          }}
          size={["sm", "sm", "md"]}
        >
          {label}
        </ButtonV2>
      </FileInputWrapper>
    );
  }
);
