import useBreakPointBasedProp from "hooks/useBreakPointBasedProp";
import { ReactComponent as Logo } from "images/logo.svg";
import FullLogo from "images/tpo/omnos-navbar-logo-mobile";

export function OmnosLogo({ size }) {
  const logoWidth = useBreakPointBasedProp(size);
  return (
    <Logo
      height={logoWidth}
      width={logoWidth}
      style={{
        minWidth: logoWidth
      }}
    />
  );
}

export function FullOmnosLogo({ size }) {
  const logoWidth = useBreakPointBasedProp(size);
  return (
    <FullLogo
      size={size}
      style={{
        minWidth: logoWidth
      }}
    />
  );
}
