import { useEffect, useState } from "react";

import Box from "components/Box";
import { ExternalTextLink } from "components/Links";
import { getAPIBase } from "core/config";
import { PAYMENT_OBJECT_STATUS_CREATED } from "core/constants";
import Badge from "tpo/Badge";
import Group from "tpo/Group";
import Stack from "tpo/Stack";
import ButtonV2 from "v2/Buttons";

export const BADGE_PROPS_FOR_PAYMENT_STATUSES = {
  "awaiting dispatch": {
    bg: "haze",
    withBorder: true,
    color: "dark"
  },
  dispatched: {
    bg: "haze",
    withBorder: true,
    color: "dark"
  },
  "at lab": {
    bg: "haze",
    withBorder: true,
    color: "dark"
  },
  failed: {
    bg: "red",
    color: "white"
  },
  cancelled: {
    bg: "red",
    color: "white"
  },
  "review results": {
    bg: "purple",
    color: "white"
  },
  "results released": {
    bg: "green",
    color: "white"
  },
  invoiced: {
    bg: "dark",
    color: "white"
  },
  "awaiting payment": {
    bg: "red",
    color: "white"
  },
  "patient to pay": {
    bg: "red",
    color: "white"
  },
  unpaid: {
    bg: "red",
    color: "white"
  },
  "over due": {
    bg: "red",
    color: "white"
  },
  "partial refund": {
    bg: "dark",
    color: "white"
  },
  refunded: {
    bg: "dark",
    color: "white"
  },
  credit: {
    bg: "dark",
    color: "white"
  },
  "patient paid": {
    bg: "green",
    color: "white"
  },
  paid: {
    bg: "green",
    color: "white"
  }
};

export default function OrganisationOrderSummary({ order }) {
  const [stripeDetails, setStripeDetails] = useState();

  useEffect(() => {
    if (!order || !order.id) {
      return;
    }

    if (order.sourceBasket.payee === "patient") {
      return setStripeDetails({
        label: "Payment:",
        value: (
          <ExternalTextLink
            onClick={e => {
              e.clickWithinOrganisationOrderSummaryComponent = true;
            }}
            href={`${getAPIBase()}/payments/checkout/${order.id}`}
          >
            Payment Link
          </ExternalTextLink>
        )
      });
    } else if (order.sourceBasket.paymentCheckoutSession) {
      if (order.sourceBasket.paymentCheckoutSession.invoiceUrl) {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink
              onClick={e => {
                e.clickWithinOrganisationOrderSummaryComponent = true;
              }}
              href={`${order.sourceBasket.paymentCheckoutSession.invoiceUrl}`}
            >
              View Invoice
            </ExternalTextLink>
          )
        });
      } else {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink
              onClick={e => {
                e.clickWithinOrganisationOrderSummaryComponent = true;
              }}
              href={`${order.sourceBasket.paymentCheckoutSession.url}`}
            >
              Payment Link
            </ExternalTextLink>
          )
        });
      }
    } else if (order.sourceBasket.paymentInvoice) {
      if (order.sourceBasket.paymentInvoice.url) {
        return setStripeDetails({
          label: "Payment:",
          value: (
            <ExternalTextLink
              onClick={e => {
                e.clickWithinOrganisationOrderSummaryComponent = true;
              }}
              href={order.sourceBasket.paymentInvoice.url}
            >
              View Invoice
            </ExternalTextLink>
          )
        });
      } else {
        return setStripeDetails({ label: "Payment:", value: "Migrated" });
      }
    } else {
      return setStripeDetails({
        label: "Payment:",
        value: "N/A"
      });
    }
  }, [order]);

  let additionalStatus = null;

  if (order.suppliedFromPracticeStock) {
    additionalStatus = "From Stock";
  } else if (order.isStockOrder) {
    additionalStatus = "Stock Order";
  }

  return (
    <Box
      display="flex"
      flexDirection={["column", "column", "row"]}
      justifyContent={["flex-start", "flex-start", "space-between"]}
      py={4}
      gap={20}
    >
      <Stack gap={10}>
        {additionalStatus && (
          <Box alignItems="flex-start" fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            <Badge bg="black" color="white" ml="auto" py="8px" px="16px" fontSize={10}>
              {additionalStatus}
            </Badge>
          </Box>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order No:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDetails:orderNo">
            {order.id}
          </Box>
        </Group>
        {stripeDetails && (
          <Group gap={10}>
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]} data-testid="stripeDetails:label">
              {stripeDetails.label}
            </Box>
            <Box
              fontFamily="gilroyMedium"
              fontSize={[14, 14, 16]}
              data-testid="stripeDetails:value"
            >
              {stripeDetails.value}
            </Box>
          </Group>
        )}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Order Date:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderDate">
            {new Date(order.checkoutDate).toLocaleDateString()}
          </Box>
        </Group>
        {order.sourceBasket &&
        order.sourceBasket.paymentInvoice &&
        order.sourceBasket.paymentInvoice.dateDue &&
        order.sourceBasket.paymentInvoice.paymentStatus === PAYMENT_OBJECT_STATUS_CREATED ? (
          <Group gap={10} alignItems="center" flexWrap="wrap">
            <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
              Payment due:
            </Box>
            <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="paymentDue">
              {new Date(order.sourceBasket.paymentInvoice.dateDue).toLocaleDateString()}
            </Box>
            {new Date(order.sourceBasket.paymentInvoice.dateDue) > new Date() &&
            order.sourceBasket.paymentInvoice.url ? (
              <ButtonV2
                as="a"
                color="green"
                href={order.sourceBasket.paymentInvoice.url}
                sx={{
                  lineHeight: 1,
                  cursor: "pointer",
                  px: 20,
                  py: 2,
                  fontSize: 12
                }}
                data-testid="payNowButton"
                onClick={e => {
                  e.clickWithinOrganisationOrderSummaryComponent = true;
                }}
                size="xs"
              >
                pay now
              </ButtonV2>
            ) : null}
          </Group>
        ) : null}
        <Group gap={10}>
          <Box fontFamily="gilroyBold" fontSize={[14, 14, 16]}>
            Name:
          </Box>
          <Box fontFamily="gilroyMedium" fontSize={[14, 14, 16]} data-testid="orderName">
            {order.user.firstName} {order.user.lastName}
          </Box>
        </Group>
      </Stack>
      <Group gap={10} alignSelf="flex-start" flexWrap="wrap">
        <Badge
          fontSize={9}
          px={16}
          py="8px"
          size="xs"
          data-testid="paymentStatusBadge"
          {...BADGE_PROPS_FOR_PAYMENT_STATUSES[order?.sourceBasket?.paymentStatus]}
        >
          {order?.sourceBasket?.paymentStatus}
        </Badge>
      </Group>
    </Box>
  );
}
