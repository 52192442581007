import gql from "graphql-tag";
import { AREA_OF_INTEREST_FIELDS } from "graphql/shared";

import { EXAMPLE_FIELDS, RECOMMENDATION_FIELDS, SUPPLEMENT_FIELDS } from "../content/types";

export const RANGE_COLOR_CONFIG_TYPE_FIELDS = gql`
  fragment RangeColorConfigTypeFields on RangeColorConfigType {
    id
    veryLow
    low
    normal
    high
    veryHigh
  }
`;

export const RANGE_DEFINITION_FIELDS = gql`
  fragment RangeDefinitionFields on RangeDefinitionType {
    id
    veryLow
    low
    high
    veryHigh
    colorConfig {
      ...RangeColorConfigTypeFields
    }
    normalRangeTerm
  }
  ${RANGE_COLOR_CONFIG_TYPE_FIELDS}
`;

export const CHART_CONFIGURATION_FIELDS = gql`
  fragment ChartConfigurationFields on ChartConfigurationType {
    chartType
    colours
    terminology
    interpretation
    interpretationQualifier
    displayName
    chartRange {
      minimum
      maximum
      range
      value
    }
    normalisedRange {
      minimum
      maximum
      range
      value
    }
  }
`;

export const USER_TEST_FIELDS_TRUNCATED = gql`
  fragment UserTestFieldsTruncated on UserTestType {
    id
    created
    createdDateFormatted
    sampleCollectedDateFormatted
    reviewStatus
    name
    slug
    user {
      id
      email
    }
    isGenesTest
    testProduct {
      id
      paymentIsoCode
      name
      slug
      truncatedName
      productFamily {
        id
        name
        familyColour
      }
    }
    orderTestItem {
      id
    }
    hasPartialOrAvailableSnapshot
  }
`;

export const USER_SNAPSHOT_FIELDS_TRUNCATED = gql`
  fragment UserSnapshotFieldsTruncated on UserSnapshotType {
    id
    snapshotDate
    publishStatus
    user {
      id
      email
    }
  }
`;

export const RESULT_TYPE_FIELDS = gql`
  fragment ResultTypeFields on ResultType {
    id
    name
    slug
    description
    function
    reason
    chartType
    displayGroup
  }
`;

export const USER_RESULT_FIELDS_TRUNCATED = gql`
  fragment UserResultFieldsTruncated on UserResultType {
    id
    result {
      ...ResultTypeFields
    }
    userSnapshot {
      id
      snapshotDate
    }
    value
    freshValue
    interpretation
    rangeUsed {
      ...RangeDefinitionFields
    }
    availableDatapointRatio
  }
  ${RANGE_DEFINITION_FIELDS}
  ${RESULT_TYPE_FIELDS}
`;

// This is used by the COT ResultGraph
// And also for the subsector result list
// It appears if both do not use exactly the same fields
// then the ResultGraph query causes the entire list query to refetch
export const USER_RESULT_FIELDS_FOR_COT = gql`
  fragment UserResultFieldsForCot on UserResultType {
    id
    value
    freshValue
    expired
    result {
      ...ResultTypeFields
      testproductSet {
        id
        paymentIsoCode
        productCode
        productFamily {
          id
          name
          familyColour
        }
      }
    }
    availableDatapointRatio
    chartConfiguration {
      ...ChartConfigurationFields
    }
    resultState {
      id
      name
    }
    userSnapshot {
      id
      snapshotDate
      snapshotType
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
  ${RESULT_TYPE_FIELDS}
`;

export const USER_DATAPOINT_FIELDS_TRUNCATED = gql`
  fragment UserDatapointFieldsTruncated on UserDatapointType {
    id
    uploaded
    datapoint {
      id
      name
      category
      units
      description
      function
      reason
      chartType
    }
    riskAllele
    value
    interpretation
    rangeUsed {
      ...RangeDefinitionFields
    }
  }
  ${RANGE_DEFINITION_FIELDS}
`;

// This is used by the COT DatapointGraph
// And also for the subsector datapoint list
// It appears if both do not use exactly the same fields
// then the DatapointGraph query causes the entire list query to refetch
export const USER_DATAPOINT_FIELDS_FOR_COT = gql`
  fragment UserDatapointFieldsForCot on UserDatapointType {
    id
    dueForRetest
    dateExpired
    dateExtracted
    uploaded
    riskAllele
    value
    interpretation
    dateExpired
    datapoint {
      id
      name
      category
      units
      description
      function
      reason
      chartType
    }
    chartConfiguration {
      ...ChartConfigurationFields
    }
    datapointState {
      id
      description
    }
  }
  ${CHART_CONFIGURATION_FIELDS}
`;

export const USER_SUBMISSION_FIELDS_TRUNCATED = gql`
  fragment UserSubmissionFieldsTruncated on UserSubmissionType {
    id
    user {
      id
      email
    }
    questionnaireDate
    complete
  }
`;

export const USER_SUBSECTOR_FIELDS = gql`
  fragment UserSubsectorFields on UserSubsectorType {
    id
    score
    subsector {
      id
      name
      sectors {
        id
        name
        theme {
          id
          name
        }
      }
    }
    results {
      ...UserResultFieldsTruncated
    }
    recommendations {
      ...RecommendationFields
    }
    examples {
      ...ExampleFields
    }
    supplements {
      ...SupplementFields
    }
  }
  ${RECOMMENDATION_FIELDS}
  ${EXAMPLE_FIELDS}
  ${SUPPLEMENT_FIELDS}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;

export const USER_SNAPSHOT_FIELDS = gql`
  fragment UserSnapshotFields on UserSnapshotType {
    id
    snapshotDate
    snapshotUserTests {
      ...UserTestFieldsTruncated
    }
    userSubsectors {
      ...UserSubsectorFields
    }
    recommendations {
      ...RecommendationFields
    }
    examples {
      ...ExampleFields
    }
    supplements {
      ...SupplementFields
    }
  }
  ${RECOMMENDATION_FIELDS}
  ${EXAMPLE_FIELDS}
  ${SUPPLEMENT_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
  ${USER_SUBSECTOR_FIELDS}
`;

export const DATAPOINT_FIELDS_TRUNCATED = gql`
  fragment DatapointFieldsTruncated on DatapointType {
    id
    name
    slug
    chartType
  }
`;

export const USER_DATAPOINT_FIELDS = gql`
  fragment UserDatapointFields on UserDatapointType {
    id
    created
    updated
    userTest {
      ...UserTestFieldsTruncated
    }
    datapoint {
      id
      name
      category
      description
      units
    }
    datapointState {
      id
      interpretation
    }
    results {
      ...UserResultFieldsTruncated
    }
    value
    interpretation
    rangeUsed {
      ...RangeDefinitionFields
    }
    recommendations {
      ...RecommendationFields
    }
  }
  ${RECOMMENDATION_FIELDS}
  ${USER_TEST_FIELDS_TRUNCATED}
  ${RANGE_DEFINITION_FIELDS}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;

export const SYMPTOM_TYPE_FIELDS = gql`
  fragment SymptomTypeFields on SymptomType {
    id
    name
    slug
  }
`;

export const USER_RESULT_FIELDS = gql`
  fragment UserResultFields on UserResultType {
    id
    availableDatapointRatio
    created
    updated
    userSnapshot {
      ...UserSnapshotFieldsTruncated
    }
    result {
      ...ResultTypeFields
    }
    resultState {
      id
      name
      description
      interpretation
      datapoints {
        id
        name
      }
    }
    value
    interpretation
    rangeUsed {
      ...RangeDefinitionFields
    }
    datapoints {
      ...UserDatapointFieldsTruncated
    }
    subresults {
      ...UserResultFieldsTruncated
    }
    recommendations {
      ...RecommendationFields
    }
    symptomsExperienced {
      ...SymptomTypeFields
    }
    otherSymptoms {
      ...SymptomTypeFields
    }
  }
  ${RECOMMENDATION_FIELDS}
  ${RESULT_TYPE_FIELDS}
  ${USER_SNAPSHOT_FIELDS_TRUNCATED}
  ${RANGE_DEFINITION_FIELDS}
  ${SYMPTOM_TYPE_FIELDS}
  ${USER_DATAPOINT_FIELDS_TRUNCATED}
  ${USER_RESULT_FIELDS_TRUNCATED}
`;

export const USER_ANSWER_FIELDS = gql`
  fragment UserAnswerFields on UserAnswerType {
    id
    question {
      id
      name
      text
    }
    answer
  }
`;

export const USER_SUBMISSION_FIELDS = gql`
  fragment UserSubmissionFields on UserSubmissionType {
    id
    user {
      id
      email
    }
    questionnaireDate
    currentSection
    answers {
      ...UserAnswerFields
    }
    complete
    sectionStatus
  }
  ${USER_ANSWER_FIELDS}
`;

export const USER_TEST_RECOMMENDATION_BADGE_FIELDS = gql`
  fragment UserTestRecommendationBadgeFields on UserTestRecommendationType {
    id
    product {
      id
      paymentIsoCode
      name
      slug
      productFamily {
        id
        slug
      }
    }
    symptoms {
      id
      name
      points
      maxPoints
      score
    }
    score
    points
    maxPoints
    rank
  }
`;

export const USER_TEST_RECOMMENDATION_FIELDS = gql`
  fragment UserTestRecommendationFields on UserTestRecommendationType {
    id
    product {
      id
      paymentIsoCode
      productAudience
      name
      rrpCurrentPrice
      rrpFullPrice
      exvatFullPrice
      tradeCurrentPrice
      tradeFullPrice
      slug
      sampleTypes
      content {
        id
        description
        image1
        categories: areasOfInterest {
          ...AreaOfInterestFields
        }
        numOfBiomarkersTested
        testType
      }
    }
    symptoms {
      id
      name
      points
      maxPoints
      score
    }
    score
    points
    maxPoints
  }
  ${AREA_OF_INTEREST_FIELDS}
`;
