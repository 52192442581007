import PoweredByRegenerus from "images/PoweredByRegenerus";
import Center from "tpo/Center";

export default function PoweredByRegenerusBanner({ color }) {
  return (
    <Center
      py="9px"
      position="absolute"
      top={0}
      left={0}
      right={0}
      className="PoweredByRegenerusBanner"
    >
      <PoweredByRegenerus fill={color} />
    </Center>
  );
}

PoweredByRegenerusBanner.defaultProps = {
  color: "white"
};
